<template>
  <form action="#" class="form" data-gw-tab="server" data-gw-tab-grp="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Server</div>
      </div>
      <select
        class="tt-uppercase"
        data-track-stab
        data-server-selection
        v-model="currentServerID"
        @change="this.$store.dispatch('form_change_server', currentServerID)"
        translate="no"
      >
        <option
          v-for="(server, index) in this.get_servers"
          :value="server.id"
          :key="index"
        >
          {{ server.name }} x{{ server.rate }}
        </option>
      </select>
    </div>

    <modal-form-server-item
      v-for="(server, index) in this.get_servers"
      :key="index"
      :server="server"
      @changeRoute="handleEvent($event)"
    ></modal-form-server-item>

    <div class="form__btn-box">
      <template v-if="!route_external">
        <router-link
          v-if="renderedRouteObject"
          :to="renderedRouteObject"
          class="button"
          data-server-btn
        >
          <span class="button__content">Continue</span>
        </router-link>
      </template>
      <template v-else>
        <span class="button__content">
          <a
            :href="this.get_servers[currentServerID - 1].desc_link"
            target="_blank"
            class="button"
            >Continue</a
          >
        </span>
      </template>
    </div>
  </form>
</template>

<script>
import ModalFormServerItem from "@/components/Body/Servers/modalFormServerItem";
import { __config } from "@/assets/js/config";

export default {
  name: "ServerForm",
  components: { ModalFormServerItem },
  data() {
    return {
      config: __config,
      route_external: false,
      renderedRouteName: null,
      renderedRouteObject: null,
      routeWithProps: false,
    };
  },
  methods: {
    handleEvent(e) {
      if (e.length) {
        this.renderedRouteName = e[0].name;
        this.route_external = false;
      } else {
        this.renderedRouteName = null;
        this.route_external = true;
        this.renderedRouteObject = null;
      }

      this.routeWithProps = this.routeWithPropsCheck;
      this.renderedRouteObject = this.renderRouteObject;
    },
  },
  props: {
    id: {
      type: Number,
      default: __config.server.default_server_id,
    },
  },
  computed: {
    get_servers() {
      return this.$store.getters.get_servers;
    },
    currentServerID: {
      get() {
        return (
          this.$store.getters.get_current_server ||
          this.$route.params.id ||
          this.$store.getters.get_default_server
        );
      },
      set(value) {
        this.$store.dispatch("update_current_server", value);
        this.$store.dispatch("form_change_server", value);
        this.$router.push({
          name: "Server",
          params: { id: value },
        });
      },
    },
    routeWithPropsCheck() {
      return !!this.$router
        .getRoutes()
        .filter(
          (r) =>
            typeof r.props.default === "function" &&
            r.name === this.renderedRouteName
        ).length;
    },
    renderRouteObject() {
      let obj;
      if (this.routeWithProps) {
        obj = {
          name: this.renderedRouteName,
          params: {
            id: this.currentServerID,
          },
        };
      } else {
        obj = {
          name: this.renderedRouteName,
        };
      }
      return obj;
    },
  },
  updated() {
    this.routeWithProps = this.routeWithPropsCheck;
    this.renderedRouteObject = this.renderRouteObject;
  },
  mounted() {
    this.renderedRouteObject = this.renderRouteObject;
    if (!this.$route.params.id) {
      let server_id = this.$props.id
        ? this.$props.id
        : this.$store.getters.get_current_server ||
          this.$store.getters.get_default_server;
      this.$router.push({
        path: "/server/" + server_id,
      });
    }
  },
};
</script>

<style scoped></style>
