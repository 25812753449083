<template>
  <div
    class="field field_grp field_mb"
    v-show="
      this.$route.params.id === server.id.toString() ||
      (server.id === this.$store.getters.get_default_server &&
        !this.$route.params.id)
    "
  >
    <div class="field__desc">
      <div class="field__ico">
        <img src="@/assets/images/icons/ico__flag.png" alt="" />
      </div>
      <div class="field__title">Section</div>
    </div>
    <select
      class="tt-uppercase"
      data-selected-server
      v-model="serverOption"
      ref="selectServerRef"
      @change="$emit('changeRoute', this.findRoute($event))"
    >
      <template v-for="(option, index) in serverOptions" :key="index">
        <option :value="option.value">
          {{ option.name }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  name: "modalFormServerItem",
  data() {
    return {
      serverOptions: [
        { value: "stats", name: "Statistics" },
        { value: "signup", name: "Registration" },
        { value: "about", name: "Description" },
        { value: "vote", name: "Vote" },
        { value: "support", name: "Support" },
        { value: "shop", name: "Shop" },
      ],
    };
  },
  computed: {
    serverOption: {
      set(value) {
        this.$store.commit("UPDATE_SERVER_OPTION", value);
      },
      get() {
        return (
          this.$store.getters.get_server_option ||
          this.$store.getters.get_default_server_option
        );
      },
    },
  },
  methods: {
    findRoute(e) {
      let val = typeof e === "object" ? e.target.value : e;
      return this.$router.getRoutes().filter((r) => r.path.includes(val));
    },
  },
  props: {
    server: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$emit("changeRoute", this.findRoute(this.serverOption));
  },
};
</script>

<style scoped></style>
